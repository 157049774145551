import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  transform?: Maybe<ImageTransformOptions>;
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Asset>>;
};

export type AssetFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  url_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  size_exists?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  size_not?: Maybe<Scalars['Int']>;
  size_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_gt?: Maybe<Scalars['Int']>;
  size_gte?: Maybe<Scalars['Int']>;
  size_lt?: Maybe<Scalars['Int']>;
  size_lte?: Maybe<Scalars['Int']>;
  contentType_exists?: Maybe<Scalars['Boolean']>;
  contentType?: Maybe<Scalars['String']>;
  contentType_not?: Maybe<Scalars['String']>;
  contentType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_contains?: Maybe<Scalars['String']>;
  contentType_not_contains?: Maybe<Scalars['String']>;
  fileName_exists?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
  fileName_not?: Maybe<Scalars['String']>;
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_contains?: Maybe<Scalars['String']>;
  fileName_not_contains?: Maybe<Scalars['String']>;
  width_exists?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  width_not?: Maybe<Scalars['Int']>;
  width_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_gt?: Maybe<Scalars['Int']>;
  width_gte?: Maybe<Scalars['Int']>;
  width_lt?: Maybe<Scalars['Int']>;
  width_lte?: Maybe<Scalars['Int']>;
  height_exists?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  height_not?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_gt?: Maybe<Scalars['Int']>;
  height_gte?: Maybe<Scalars['Int']>;
  height_lt?: Maybe<Scalars['Int']>;
  height_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<AssetFilter>>>;
  AND?: Maybe<Array<Maybe<AssetFilter>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  deckCollection?: Maybe<DeckCollection>;
  personCollection?: Maybe<PersonCollection>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsDeckCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsPersonCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum AssetOrder {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategory = Entry & {
  __typename?: 'BlogCategory';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BlogCategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<BlogCategoryDescription>;
  priority?: Maybe<Scalars['Int']>;
};


/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategoryInternalNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategorySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategoryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of blog posts [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogCategory) */
export type BlogCategoryPriorityArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type BlogCategoryCollection = {
  __typename?: 'BlogCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BlogCategory>>;
};

export type BlogCategoryDescription = {
  __typename?: 'BlogCategoryDescription';
  json: Scalars['JSON'];
  links: BlogCategoryDescriptionLinks;
};

export type BlogCategoryDescriptionAssets = {
  __typename?: 'BlogCategoryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type BlogCategoryDescriptionEntries = {
  __typename?: 'BlogCategoryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type BlogCategoryDescriptionLinks = {
  __typename?: 'BlogCategoryDescriptionLinks';
  entries: BlogCategoryDescriptionEntries;
  assets: BlogCategoryDescriptionAssets;
};

export type BlogCategoryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  internalName_exists?: Maybe<Scalars['Boolean']>;
  internalName?: Maybe<Scalars['String']>;
  internalName_not?: Maybe<Scalars['String']>;
  internalName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalName_contains?: Maybe<Scalars['String']>;
  internalName_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  priority_exists?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<BlogCategoryFilter>>>;
  AND?: Maybe<Array<Maybe<BlogCategoryFilter>>>;
};

export type BlogCategoryLinkingCollections = {
  __typename?: 'BlogCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  blogEntryCollection?: Maybe<BlogEntryCollection>;
};


export type BlogCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type BlogCategoryLinkingCollectionsBlogEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum BlogCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntry = Entry & {
  __typename?: 'BlogEntry';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BlogEntryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<BlogEntryDescription>;
  deck?: Maybe<Deck>;
  blogCategoryCollection?: Maybe<BlogEntryBlogCategoryCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntrySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntryDeckArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/blogEntry) */
export type BlogEntryBlogCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type BlogEntryBlogCategoryCollection = {
  __typename?: 'BlogEntryBlogCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BlogCategory>>;
};

export type BlogEntryCollection = {
  __typename?: 'BlogEntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BlogEntry>>;
};

export type BlogEntryDescription = {
  __typename?: 'BlogEntryDescription';
  json: Scalars['JSON'];
  links: BlogEntryDescriptionLinks;
};

export type BlogEntryDescriptionAssets = {
  __typename?: 'BlogEntryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type BlogEntryDescriptionEntries = {
  __typename?: 'BlogEntryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type BlogEntryDescriptionLinks = {
  __typename?: 'BlogEntryDescriptionLinks';
  entries: BlogEntryDescriptionEntries;
  assets: BlogEntryDescriptionAssets;
};

export type BlogEntryFilter = {
  deck?: Maybe<CfDeckNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  deck_exists?: Maybe<Scalars['Boolean']>;
  blogCategoryCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<BlogEntryFilter>>>;
  AND?: Maybe<Array<Maybe<BlogEntryFilter>>>;
};

export type BlogEntryLinkingCollections = {
  __typename?: 'BlogEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type BlogEntryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum BlogEntryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags_exists?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<ContentfulMetadataTagsFilter>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type Deck = Entry & {
  __typename?: 'Deck';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DeckLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['String']>;
  previewCardId?: Maybe<Scalars['String']>;
  stagingDocId?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  previewImage?: Maybe<Asset>;
  authorDisplayName?: Maybe<Scalars['String']>;
  authorImage?: Maybe<Asset>;
  author?: Maybe<Person>;
  slug?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckDocIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckPreviewCardIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckStagingDocIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckIsNewArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckPreviewImageArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckAuthorDisplayNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckAuthorImageArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckAuthorArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A pointer to an actual deck in Gamma, with custom fields that we can override. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deck) */
export type DeckSlugArgs = {
  locale?: Maybe<Scalars['String']>;
};

/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategory = Entry & {
  __typename?: 'DeckCategory';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DeckCategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  parentCollection?: Maybe<DeckCollection>;
  description?: Maybe<DeckCategoryDescription>;
  decksCollection?: Maybe<DeckCategoryDecksCollection>;
  priority?: Maybe<Scalars['Int']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategorySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryParentCollectionArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryDecksCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** Categories under which we can sort decks, e.g. "Popular decks" [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCategory) */
export type DeckCategoryPriorityArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type DeckCategoryCollection = {
  __typename?: 'DeckCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DeckCategory>>;
};

export type DeckCategoryDecksCollection = {
  __typename?: 'DeckCategoryDecksCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Deck>>;
};

export type DeckCategoryDescription = {
  __typename?: 'DeckCategoryDescription';
  json: Scalars['JSON'];
  links: DeckCategoryDescriptionLinks;
};

export type DeckCategoryDescriptionAssets = {
  __typename?: 'DeckCategoryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type DeckCategoryDescriptionEntries = {
  __typename?: 'DeckCategoryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type DeckCategoryDescriptionLinks = {
  __typename?: 'DeckCategoryDescriptionLinks';
  entries: DeckCategoryDescriptionEntries;
  assets: DeckCategoryDescriptionAssets;
};

export type DeckCategoryFilter = {
  parentCollection?: Maybe<CfDeckCollectionNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  parentCollection_exists?: Maybe<Scalars['Boolean']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  decksCollection_exists?: Maybe<Scalars['Boolean']>;
  priority_exists?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<DeckCategoryFilter>>>;
  AND?: Maybe<Array<Maybe<DeckCategoryFilter>>>;
};

export type DeckCategoryLinkingCollections = {
  __typename?: 'DeckCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type DeckCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum DeckCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollection = Entry & {
  __typename?: 'DeckCollection';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DeckCollectionLinkingCollections>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A collection of decks, divided into categories. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/deckCollection) */
export type DeckCollectionDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type DeckCollectionCollection = {
  __typename?: 'DeckCollectionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DeckCollection>>;
};

export type DeckCollectionFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<DeckCollectionFilter>>>;
  AND?: Maybe<Array<Maybe<DeckCollectionFilter>>>;
};

export type DeckCollectionLinkingCollections = {
  __typename?: 'DeckCollectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  deckCategoryCollection?: Maybe<DeckCategoryCollection>;
};


export type DeckCollectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckCollectionLinkingCollectionsDeckCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum DeckCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type DeckLinkingCollections = {
  __typename?: 'DeckLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  templateEntryCollection?: Maybe<TemplateEntryCollection>;
  inspirationEntryCollection?: Maybe<InspirationEntryCollection>;
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  deckCategoryCollection?: Maybe<DeckCategoryCollection>;
};


export type DeckLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckLinkingCollectionsTemplateEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckLinkingCollectionsInspirationEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckLinkingCollectionsBlogEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DeckLinkingCollectionsDeckCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type Entry = {
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type EntryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  OR?: Maybe<Array<Maybe<EntryFilter>>>;
  AND?: Maybe<Array<Maybe<EntryFilter>>>;
};

export enum EntryOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** For the Featured Templates carousel on the Templates home [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/featuredTemplates) */
export type FeaturedTemplates = Entry & {
  __typename?: 'FeaturedTemplates';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<FeaturedTemplatesLinkingCollections>;
  templateEntriesCollection?: Maybe<FeaturedTemplatesTemplateEntriesCollection>;
  slug?: Maybe<Scalars['String']>;
};


/** For the Featured Templates carousel on the Templates home [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/featuredTemplates) */
export type FeaturedTemplatesLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** For the Featured Templates carousel on the Templates home [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/featuredTemplates) */
export type FeaturedTemplatesTemplateEntriesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** For the Featured Templates carousel on the Templates home [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/featuredTemplates) */
export type FeaturedTemplatesSlugArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type FeaturedTemplatesCollection = {
  __typename?: 'FeaturedTemplatesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<FeaturedTemplates>>;
};

export type FeaturedTemplatesFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  templateEntriesCollection_exists?: Maybe<Scalars['Boolean']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<FeaturedTemplatesFilter>>>;
  AND?: Maybe<Array<Maybe<FeaturedTemplatesFilter>>>;
};

export type FeaturedTemplatesLinkingCollections = {
  __typename?: 'FeaturedTemplatesLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type FeaturedTemplatesLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum FeaturedTemplatesOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type FeaturedTemplatesTemplateEntriesCollection = {
  __typename?: 'FeaturedTemplatesTemplateEntriesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TemplateEntry>>;
};

/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStarted = Entry & {
  __typename?: 'GettingStarted';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GettingStartedLinkingCollections>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  optionsCollection?: Maybe<GettingStartedOptionsCollection>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStarted) */
export type GettingStartedOptionsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type GettingStartedCollection = {
  __typename?: 'GettingStartedCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<GettingStarted>>;
};

export type GettingStartedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  optionsCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<GettingStartedFilter>>>;
  AND?: Maybe<Array<Maybe<GettingStartedFilter>>>;
};

export type GettingStartedLinkingCollections = {
  __typename?: 'GettingStartedLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type GettingStartedLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type GettingStartedOptionsCollection = {
  __typename?: 'GettingStartedOptionsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<GettingStartedStep>>;
};

export enum GettingStartedOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStep = Entry & {
  __typename?: 'GettingStartedStep';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<GettingStartedStepLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<GettingStartedStepBody>;
  colorScheme?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepBodyArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepColorSchemeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepPreviewImageArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepIconNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** One of the options in the Getting Started Modal [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/gettingStartedStep) */
export type GettingStartedStepActionArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type GettingStartedStepBody = {
  __typename?: 'GettingStartedStepBody';
  json: Scalars['JSON'];
  links: GettingStartedStepBodyLinks;
};

export type GettingStartedStepBodyAssets = {
  __typename?: 'GettingStartedStepBodyAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type GettingStartedStepBodyEntries = {
  __typename?: 'GettingStartedStepBodyEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type GettingStartedStepBodyLinks = {
  __typename?: 'GettingStartedStepBodyLinks';
  entries: GettingStartedStepBodyEntries;
  assets: GettingStartedStepBodyAssets;
};

export type GettingStartedStepCollection = {
  __typename?: 'GettingStartedStepCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<GettingStartedStep>>;
};

export type GettingStartedStepFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  body_exists?: Maybe<Scalars['Boolean']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  colorScheme_exists?: Maybe<Scalars['Boolean']>;
  colorScheme?: Maybe<Scalars['String']>;
  colorScheme_not?: Maybe<Scalars['String']>;
  colorScheme_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorScheme_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorScheme_contains?: Maybe<Scalars['String']>;
  colorScheme_not_contains?: Maybe<Scalars['String']>;
  previewImage_exists?: Maybe<Scalars['Boolean']>;
  previewImage?: Maybe<Scalars['String']>;
  previewImage_not?: Maybe<Scalars['String']>;
  previewImage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewImage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewImage_contains?: Maybe<Scalars['String']>;
  previewImage_not_contains?: Maybe<Scalars['String']>;
  iconName_exists?: Maybe<Scalars['Boolean']>;
  iconName?: Maybe<Scalars['String']>;
  iconName_not?: Maybe<Scalars['String']>;
  iconName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iconName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iconName_contains?: Maybe<Scalars['String']>;
  iconName_not_contains?: Maybe<Scalars['String']>;
  action_exists?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_not?: Maybe<Scalars['String']>;
  action_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  action_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  action_contains?: Maybe<Scalars['String']>;
  action_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<GettingStartedStepFilter>>>;
  AND?: Maybe<Array<Maybe<GettingStartedStepFilter>>>;
};

export type GettingStartedStepLinkingCollections = {
  __typename?: 'GettingStartedStepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  gettingStartedCollection?: Maybe<GettingStartedCollection>;
};


export type GettingStartedStepLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type GettingStartedStepLinkingCollectionsGettingStartedCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum GettingStartedStepOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ColorSchemeAsc = 'colorScheme_ASC',
  ColorSchemeDesc = 'colorScheme_DESC',
  PreviewImageAsc = 'previewImage_ASC',
  PreviewImageDesc = 'previewImage_DESC',
  IconNameAsc = 'iconName_ASC',
  IconNameDesc = 'iconName_DESC',
  ActionAsc = 'action_ASC',
  ActionDesc = 'action_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export enum ImageFormat {
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
  Avif = 'AVIF'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES'
}

export enum ImageResizeStrategy {
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /** Desired width in pixels. Defaults to the original image width. */
  width?: Maybe<Scalars['Dimension']>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: Maybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: Maybe<Scalars['Quality']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: Maybe<Scalars['Int']>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: Maybe<ImageResizeStrategy>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: Maybe<ImageResizeFocus>;
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: Maybe<Scalars['HexColor']>;
  /** Desired image format. Defaults to the original image format. */
  format?: Maybe<ImageFormat>;
};

/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategory = Entry & {
  __typename?: 'InspirationCategory';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<InspirationCategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<InspirationCategoryDescription>;
  inspirationEntriesCollection?: Maybe<InspirationCategoryInspirationEntriesCollection>;
  priority?: Maybe<Scalars['Int']>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategoryInternalNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategorySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategoryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategoryInspirationEntriesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A category holding a collection of Inspiration Entries. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationCategory) */
export type InspirationCategoryPriorityArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type InspirationCategoryCollection = {
  __typename?: 'InspirationCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<InspirationCategory>>;
};

export type InspirationCategoryDescription = {
  __typename?: 'InspirationCategoryDescription';
  json: Scalars['JSON'];
  links: InspirationCategoryDescriptionLinks;
};

export type InspirationCategoryDescriptionAssets = {
  __typename?: 'InspirationCategoryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type InspirationCategoryDescriptionEntries = {
  __typename?: 'InspirationCategoryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type InspirationCategoryDescriptionLinks = {
  __typename?: 'InspirationCategoryDescriptionLinks';
  entries: InspirationCategoryDescriptionEntries;
  assets: InspirationCategoryDescriptionAssets;
};

export type InspirationCategoryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  internalName_exists?: Maybe<Scalars['Boolean']>;
  internalName?: Maybe<Scalars['String']>;
  internalName_not?: Maybe<Scalars['String']>;
  internalName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalName_contains?: Maybe<Scalars['String']>;
  internalName_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  inspirationEntriesCollection_exists?: Maybe<Scalars['Boolean']>;
  priority_exists?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<InspirationCategoryFilter>>>;
  AND?: Maybe<Array<Maybe<InspirationCategoryFilter>>>;
};

export type InspirationCategoryInspirationEntriesCollection = {
  __typename?: 'InspirationCategoryInspirationEntriesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<InspirationEntry>>;
};

export type InspirationCategoryLinkingCollections = {
  __typename?: 'InspirationCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type InspirationCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum InspirationCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationEntry) */
export type InspirationEntry = Entry & {
  __typename?: 'InspirationEntry';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<InspirationEntryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<InspirationEntryDescription>;
  deck?: Maybe<Deck>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationEntry) */
export type InspirationEntryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationEntry) */
export type InspirationEntryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationEntry) */
export type InspirationEntrySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationEntry) */
export type InspirationEntryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/inspirationEntry) */
export type InspirationEntryDeckArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type InspirationEntryCollection = {
  __typename?: 'InspirationEntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<InspirationEntry>>;
};

export type InspirationEntryDescription = {
  __typename?: 'InspirationEntryDescription';
  json: Scalars['JSON'];
  links: InspirationEntryDescriptionLinks;
};

export type InspirationEntryDescriptionAssets = {
  __typename?: 'InspirationEntryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type InspirationEntryDescriptionEntries = {
  __typename?: 'InspirationEntryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type InspirationEntryDescriptionLinks = {
  __typename?: 'InspirationEntryDescriptionLinks';
  entries: InspirationEntryDescriptionEntries;
  assets: InspirationEntryDescriptionAssets;
};

export type InspirationEntryFilter = {
  deck?: Maybe<CfDeckNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  deck_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<InspirationEntryFilter>>>;
  AND?: Maybe<Array<Maybe<InspirationEntryFilter>>>;
};

export type InspirationEntryLinkingCollections = {
  __typename?: 'InspirationEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  inspirationCategoryCollection?: Maybe<InspirationCategoryCollection>;
};


export type InspirationEntryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type InspirationEntryLinkingCollectionsInspirationCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum InspirationEntryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type Person = Entry & {
  __typename?: 'Person';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PersonLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  shortBio?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonImageArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonShortBioArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonEmailArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonPhoneArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonFacebookArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A block that represents the author of any piece of content. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/person) */
export type PersonTwitterArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type PersonCollection = {
  __typename?: 'PersonCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Person>>;
};

export type PersonFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  image_exists?: Maybe<Scalars['Boolean']>;
  shortBio_exists?: Maybe<Scalars['Boolean']>;
  shortBio?: Maybe<Scalars['String']>;
  shortBio_not?: Maybe<Scalars['String']>;
  shortBio_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortBio_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortBio_contains?: Maybe<Scalars['String']>;
  shortBio_not_contains?: Maybe<Scalars['String']>;
  email_exists?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  phone_exists?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  facebook_exists?: Maybe<Scalars['Boolean']>;
  facebook?: Maybe<Scalars['String']>;
  facebook_not?: Maybe<Scalars['String']>;
  facebook_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_contains?: Maybe<Scalars['String']>;
  facebook_not_contains?: Maybe<Scalars['String']>;
  twitter_exists?: Maybe<Scalars['Boolean']>;
  twitter?: Maybe<Scalars['String']>;
  twitter_not?: Maybe<Scalars['String']>;
  twitter_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  twitter_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  twitter_contains?: Maybe<Scalars['String']>;
  twitter_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<PersonFilter>>>;
  AND?: Maybe<Array<Maybe<PersonFilter>>>;
};

export type PersonLinkingCollections = {
  __typename?: 'PersonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  deckCollection?: Maybe<DeckCollection>;
};


export type PersonLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type PersonLinkingCollectionsDeckCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum PersonOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  FacebookAsc = 'facebook_ASC',
  FacebookDesc = 'facebook_DESC',
  TwitterAsc = 'twitter_ASC',
  TwitterDesc = 'twitter_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  templateEntry?: Maybe<TemplateEntry>;
  templateEntryCollection?: Maybe<TemplateEntryCollection>;
  featuredTemplates?: Maybe<FeaturedTemplates>;
  featuredTemplatesCollection?: Maybe<FeaturedTemplatesCollection>;
  deck?: Maybe<Deck>;
  deckCollection?: Maybe<DeckCollection>;
  inspirationCategory?: Maybe<InspirationCategory>;
  inspirationCategoryCollection?: Maybe<InspirationCategoryCollection>;
  templateCategory?: Maybe<TemplateCategory>;
  templateCategoryCollection?: Maybe<TemplateCategoryCollection>;
  inspirationEntry?: Maybe<InspirationEntry>;
  inspirationEntryCollection?: Maybe<InspirationEntryCollection>;
  blogEntry?: Maybe<BlogEntry>;
  blogEntryCollection?: Maybe<BlogEntryCollection>;
  blogCategory?: Maybe<BlogCategory>;
  blogCategoryCollection?: Maybe<BlogCategoryCollection>;
  person?: Maybe<Person>;
  personCollection?: Maybe<PersonCollection>;
  deckCategory?: Maybe<DeckCategory>;
  deckCategoryCollection?: Maybe<DeckCategoryCollection>;
  gettingStartedStep?: Maybe<GettingStartedStep>;
  gettingStartedStepCollection?: Maybe<GettingStartedStepCollection>;
  gettingStarted?: Maybe<GettingStarted>;
  gettingStartedCollection?: Maybe<GettingStartedCollection>;
  tourStep?: Maybe<TourStep>;
  tourStepCollection?: Maybe<TourStepCollection>;
  tour?: Maybe<Tour>;
  tourCollection?: Maybe<TourCollection>;
  deckCollectionCollection?: Maybe<DeckCollectionCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<AssetFilter>;
  order?: Maybe<Array<Maybe<AssetOrder>>>;
};


export type QueryTemplateEntryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTemplateEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<TemplateEntryFilter>;
  order?: Maybe<Array<Maybe<TemplateEntryOrder>>>;
};


export type QueryFeaturedTemplatesArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryFeaturedTemplatesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<FeaturedTemplatesFilter>;
  order?: Maybe<Array<Maybe<FeaturedTemplatesOrder>>>;
};


export type QueryDeckArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryDeckCollectionArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryInspirationCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryInspirationCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<InspirationCategoryFilter>;
  order?: Maybe<Array<Maybe<InspirationCategoryOrder>>>;
};


export type QueryTemplateCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTemplateCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<TemplateCategoryFilter>;
  order?: Maybe<Array<Maybe<TemplateCategoryOrder>>>;
};


export type QueryInspirationEntryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryInspirationEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<InspirationEntryFilter>;
  order?: Maybe<Array<Maybe<InspirationEntryOrder>>>;
};


export type QueryBlogEntryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryBlogEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<BlogEntryFilter>;
  order?: Maybe<Array<Maybe<BlogEntryOrder>>>;
};


export type QueryBlogCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryBlogCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<BlogCategoryFilter>;
  order?: Maybe<Array<Maybe<BlogCategoryOrder>>>;
};


export type QueryPersonArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryPersonCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<PersonFilter>;
  order?: Maybe<Array<Maybe<PersonOrder>>>;
};


export type QueryDeckCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryDeckCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<DeckCategoryFilter>;
  order?: Maybe<Array<Maybe<DeckCategoryOrder>>>;
};


export type QueryGettingStartedStepArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGettingStartedStepCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<GettingStartedStepFilter>;
  order?: Maybe<Array<Maybe<GettingStartedStepOrder>>>;
};


export type QueryGettingStartedArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryGettingStartedCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<GettingStartedFilter>;
  order?: Maybe<Array<Maybe<GettingStartedOrder>>>;
};


export type QueryTourStepArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTourStepCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<TourStepFilter>;
  order?: Maybe<Array<Maybe<TourStepOrder>>>;
};


export type QueryTourArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryTourCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<TourFilter>;
  order?: Maybe<Array<Maybe<TourOrder>>>;
};


export type QueryDeckCollectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<DeckCollectionFilter>;
  order?: Maybe<Array<Maybe<DeckCollectionOrder>>>;
};


export type QueryEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<EntryFilter>;
  order?: Maybe<Array<Maybe<EntryOrder>>>;
};

export type Sys = {
  __typename?: 'Sys';
  id: Scalars['String'];
  spaceId: Scalars['String'];
  environmentId: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
};

export type SysFilter = {
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  publishedAt_exists?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: Maybe<Scalars['Boolean']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_not?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_gt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: Maybe<Scalars['DateTime']>;
  publishedVersion_exists?: Maybe<Scalars['Boolean']>;
  publishedVersion?: Maybe<Scalars['Float']>;
  publishedVersion_not?: Maybe<Scalars['Float']>;
  publishedVersion_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_gt?: Maybe<Scalars['Float']>;
  publishedVersion_gte?: Maybe<Scalars['Float']>;
  publishedVersion_lt?: Maybe<Scalars['Float']>;
  publishedVersion_lte?: Maybe<Scalars['Float']>;
};

/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategory = Entry & {
  __typename?: 'TemplateCategory';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TemplateCategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<TemplateCategoryDescription>;
  templateEntriesCollection?: Maybe<TemplateCategoryTemplateEntriesCollection>;
  priority?: Maybe<Scalars['Int']>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategoryInternalNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategorySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategoryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategoryTemplateEntriesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** A category that holds a collection of template pages. Examples include "Product Management", "Meetings", "Productivity", etc. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateCategory) */
export type TemplateCategoryPriorityArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type TemplateCategoryCollection = {
  __typename?: 'TemplateCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TemplateCategory>>;
};

export type TemplateCategoryDescription = {
  __typename?: 'TemplateCategoryDescription';
  json: Scalars['JSON'];
  links: TemplateCategoryDescriptionLinks;
};

export type TemplateCategoryDescriptionAssets = {
  __typename?: 'TemplateCategoryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type TemplateCategoryDescriptionEntries = {
  __typename?: 'TemplateCategoryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type TemplateCategoryDescriptionLinks = {
  __typename?: 'TemplateCategoryDescriptionLinks';
  entries: TemplateCategoryDescriptionEntries;
  assets: TemplateCategoryDescriptionAssets;
};

export type TemplateCategoryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  internalName_exists?: Maybe<Scalars['Boolean']>;
  internalName?: Maybe<Scalars['String']>;
  internalName_not?: Maybe<Scalars['String']>;
  internalName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  internalName_contains?: Maybe<Scalars['String']>;
  internalName_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  templateEntriesCollection_exists?: Maybe<Scalars['Boolean']>;
  priority_exists?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<TemplateCategoryFilter>>>;
  AND?: Maybe<Array<Maybe<TemplateCategoryFilter>>>;
};

export type TemplateCategoryLinkingCollections = {
  __typename?: 'TemplateCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type TemplateCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum TemplateCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type TemplateCategoryTemplateEntriesCollection = {
  __typename?: 'TemplateCategoryTemplateEntriesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TemplateEntry>>;
};

/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateEntry) */
export type TemplateEntry = Entry & {
  __typename?: 'TemplateEntry';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TemplateEntryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<TemplateEntryDescription>;
  deck?: Maybe<Deck>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateEntry) */
export type TemplateEntryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateEntry) */
export type TemplateEntryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateEntry) */
export type TemplateEntrySlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateEntry) */
export type TemplateEntryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/templateEntry) */
export type TemplateEntryDeckArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type TemplateEntryCollection = {
  __typename?: 'TemplateEntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TemplateEntry>>;
};

export type TemplateEntryDescription = {
  __typename?: 'TemplateEntryDescription';
  json: Scalars['JSON'];
  links: TemplateEntryDescriptionLinks;
};

export type TemplateEntryDescriptionAssets = {
  __typename?: 'TemplateEntryDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type TemplateEntryDescriptionEntries = {
  __typename?: 'TemplateEntryDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type TemplateEntryDescriptionLinks = {
  __typename?: 'TemplateEntryDescriptionLinks';
  entries: TemplateEntryDescriptionEntries;
  assets: TemplateEntryDescriptionAssets;
};

export type TemplateEntryFilter = {
  deck?: Maybe<CfDeckNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  deck_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<TemplateEntryFilter>>>;
  AND?: Maybe<Array<Maybe<TemplateEntryFilter>>>;
};

export type TemplateEntryLinkingCollections = {
  __typename?: 'TemplateEntryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  featuredTemplatesCollection?: Maybe<FeaturedTemplatesCollection>;
  templateCategoryCollection?: Maybe<TemplateCategoryCollection>;
};


export type TemplateEntryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type TemplateEntryLinkingCollectionsFeaturedTemplatesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type TemplateEntryLinkingCollectionsTemplateCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum TemplateEntryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type Tour = Entry & {
  __typename?: 'Tour';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TourLinkingCollections>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  stepsCollection?: Maybe<TourStepsCollection>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Container for tour steps [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tour) */
export type TourStepsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type TourCollection = {
  __typename?: 'TourCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Tour>>;
};

export type TourFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  stepsCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<TourFilter>>>;
  AND?: Maybe<Array<Maybe<TourFilter>>>;
};

export type TourLinkingCollections = {
  __typename?: 'TourLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type TourLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum TourOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStep = Entry & {
  __typename?: 'TourStep';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TourStepLinkingCollections>;
  headline?: Maybe<Scalars['String']>;
  body?: Maybe<TourStepBody>;
  videoUrl?: Maybe<Scalars['String']>;
  posterUrl?: Maybe<Scalars['String']>;
  staticImageUrl?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepHeadlineArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepBodyArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepVideoUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepPosterUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** The steps that make up a tour. [See type definition](https://app.contentful.com/spaces/yg6st2np8vj7/content_types/tourStep) */
export type TourStepStaticImageUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type TourStepBody = {
  __typename?: 'TourStepBody';
  json: Scalars['JSON'];
  links: TourStepBodyLinks;
};

export type TourStepBodyAssets = {
  __typename?: 'TourStepBodyAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type TourStepBodyEntries = {
  __typename?: 'TourStepBodyEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type TourStepBodyLinks = {
  __typename?: 'TourStepBodyLinks';
  entries: TourStepBodyEntries;
  assets: TourStepBodyAssets;
};

export type TourStepCollection = {
  __typename?: 'TourStepCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TourStep>>;
};

export type TourStepFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  headline_exists?: Maybe<Scalars['Boolean']>;
  headline?: Maybe<Scalars['String']>;
  headline_not?: Maybe<Scalars['String']>;
  headline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_contains?: Maybe<Scalars['String']>;
  headline_not_contains?: Maybe<Scalars['String']>;
  body_exists?: Maybe<Scalars['Boolean']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  videoUrl_exists?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  videoUrl_not?: Maybe<Scalars['String']>;
  videoUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoUrl_contains?: Maybe<Scalars['String']>;
  videoUrl_not_contains?: Maybe<Scalars['String']>;
  posterUrl_exists?: Maybe<Scalars['Boolean']>;
  posterUrl?: Maybe<Scalars['String']>;
  posterUrl_not?: Maybe<Scalars['String']>;
  posterUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  posterUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  posterUrl_contains?: Maybe<Scalars['String']>;
  posterUrl_not_contains?: Maybe<Scalars['String']>;
  staticImageUrl_exists?: Maybe<Scalars['Boolean']>;
  staticImageUrl?: Maybe<Scalars['String']>;
  staticImageUrl_not?: Maybe<Scalars['String']>;
  staticImageUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  staticImageUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  staticImageUrl_contains?: Maybe<Scalars['String']>;
  staticImageUrl_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<TourStepFilter>>>;
  AND?: Maybe<Array<Maybe<TourStepFilter>>>;
};

export type TourStepLinkingCollections = {
  __typename?: 'TourStepLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  tourCollection?: Maybe<TourCollection>;
};


export type TourStepLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type TourStepLinkingCollectionsTourCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum TourStepOrder {
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  VideoUrlAsc = 'videoUrl_ASC',
  VideoUrlDesc = 'videoUrl_DESC',
  PosterUrlAsc = 'posterUrl_ASC',
  PosterUrlDesc = 'posterUrl_DESC',
  StaticImageUrlAsc = 'staticImageUrl_ASC',
  StaticImageUrlDesc = 'staticImageUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type TourStepsCollection = {
  __typename?: 'TourStepsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<TourStep>>;
};

export type CfDeckCollectionNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<CfDeckCollectionNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfDeckCollectionNestedFilter>>>;
};

export type CfDeckNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  docId_exists?: Maybe<Scalars['Boolean']>;
  docId?: Maybe<Scalars['String']>;
  docId_not?: Maybe<Scalars['String']>;
  docId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  docId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  docId_contains?: Maybe<Scalars['String']>;
  docId_not_contains?: Maybe<Scalars['String']>;
  previewCardId_exists?: Maybe<Scalars['Boolean']>;
  previewCardId?: Maybe<Scalars['String']>;
  previewCardId_not?: Maybe<Scalars['String']>;
  previewCardId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewCardId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewCardId_contains?: Maybe<Scalars['String']>;
  previewCardId_not_contains?: Maybe<Scalars['String']>;
  stagingDocId_exists?: Maybe<Scalars['Boolean']>;
  stagingDocId?: Maybe<Scalars['String']>;
  stagingDocId_not?: Maybe<Scalars['String']>;
  stagingDocId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  stagingDocId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  stagingDocId_contains?: Maybe<Scalars['String']>;
  stagingDocId_not_contains?: Maybe<Scalars['String']>;
  isNew_exists?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isNew_not?: Maybe<Scalars['Boolean']>;
  previewImage_exists?: Maybe<Scalars['Boolean']>;
  authorDisplayName_exists?: Maybe<Scalars['Boolean']>;
  authorDisplayName?: Maybe<Scalars['String']>;
  authorDisplayName_not?: Maybe<Scalars['String']>;
  authorDisplayName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorDisplayName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorDisplayName_contains?: Maybe<Scalars['String']>;
  authorDisplayName_not_contains?: Maybe<Scalars['String']>;
  authorImage_exists?: Maybe<Scalars['Boolean']>;
  author_exists?: Maybe<Scalars['Boolean']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<CfDeckNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfDeckNestedFilter>>>;
};

export type InspirationCategoryFieldsFragmentFragment = (
  { __typename?: 'InspirationCategory' }
  & Pick<InspirationCategory, 'title' | 'slug'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id'>
  ), description?: Maybe<(
    { __typename?: 'InspirationCategoryDescription' }
    & Pick<InspirationCategoryDescription, 'json'>
  )> }
);

export type TemplateCategoryFieldsFragmentFragment = (
  { __typename?: 'TemplateCategory' }
  & Pick<TemplateCategory, 'title' | 'slug'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id'>
  ), description?: Maybe<(
    { __typename?: 'TemplateCategoryDescription' }
    & Pick<TemplateCategoryDescription, 'json'>
  )> }
);

export type InspirationEntryFieldsFragmentFragment = (
  { __typename?: 'InspirationEntry' }
  & Pick<InspirationEntry, 'title' | 'slug'>
  & { description?: Maybe<(
    { __typename?: 'InspirationEntryDescription' }
    & Pick<InspirationEntryDescription, 'json'>
  )>, sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'firstPublishedAt'>
  ), deck?: Maybe<(
    { __typename?: 'Deck' }
    & Pick<Deck, 'title' | 'docId' | 'previewCardId'>
    & { previewImage?: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
    )>, author?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'name'>
      & { image?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'url'>
      )> }
    )> }
  )> }
);

export type TemplateEntryFieldsFragmentFragment = (
  { __typename?: 'TemplateEntry' }
  & Pick<TemplateEntry, 'title' | 'slug'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'firstPublishedAt'>
  ), deck?: Maybe<(
    { __typename?: 'Deck' }
    & Pick<Deck, 'title' | 'docId' | 'previewCardId'>
    & { previewImage?: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
    )>, author?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'name'>
      & { image?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'url'>
      )> }
    )> }
  )> }
);

export type TemplateCategoryFieldsWithEntriesFragmentFragment = (
  { __typename?: 'TemplateCategory' }
  & { templateEntriesCollection?: Maybe<(
    { __typename?: 'TemplateCategoryTemplateEntriesCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'TemplateEntry' }
      & TemplateEntryFieldsFragmentFragment
    )>> }
  )> }
  & TemplateCategoryFieldsFragmentFragment
);

export type GetInspirationCategoryCollectionQueryVariables = Exact<{
  where?: Maybe<InspirationCategoryFilter>;
}>;


export type GetInspirationCategoryCollectionQuery = (
  { __typename?: 'Query' }
  & { inspirationCategoryCollection?: Maybe<(
    { __typename?: 'InspirationCategoryCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'InspirationCategory' }
      & { inspirationEntriesCollection?: Maybe<(
        { __typename?: 'InspirationCategoryInspirationEntriesCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'InspirationEntry' }
          & InspirationEntryFieldsFragmentFragment
        )>> }
      )> }
      & InspirationCategoryFieldsFragmentFragment
    )>> }
  )> }
);

export type GetTemplateCategoryCollectionQueryVariables = Exact<{
  where?: Maybe<TemplateCategoryFilter>;
}>;


export type GetTemplateCategoryCollectionQuery = (
  { __typename?: 'Query' }
  & { templateCategoryCollection?: Maybe<(
    { __typename?: 'TemplateCategoryCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'TemplateCategory' }
      & { templateEntriesCollection?: Maybe<(
        { __typename?: 'TemplateCategoryTemplateEntriesCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'TemplateEntry' }
          & TemplateEntryFieldsFragmentFragment
        )>> }
      )> }
      & TemplateCategoryFieldsFragmentFragment
    )>> }
  )> }
);

export type GetTemplateEntryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTemplateEntryQuery = (
  { __typename?: 'Query' }
  & { templateEntry?: Maybe<(
    { __typename?: 'TemplateEntry' }
    & { description?: Maybe<(
      { __typename?: 'TemplateEntryDescription' }
      & Pick<TemplateEntryDescription, 'json'>
    )>, linkedFrom?: Maybe<(
      { __typename?: 'TemplateEntryLinkingCollections' }
      & { templateCategoryCollection?: Maybe<(
        { __typename?: 'TemplateCategoryCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'TemplateCategory' }
          & TemplateCategoryFieldsWithEntriesFragmentFragment
        )>> }
      )> }
    )> }
    & TemplateEntryFieldsFragmentFragment
  )> }
);

export type GetAllTemplateEntriesQueryVariables = Exact<{
  where?: Maybe<TemplateEntryFilter>;
}>;


export type GetAllTemplateEntriesQuery = (
  { __typename?: 'Query' }
  & { templateEntryCollection?: Maybe<(
    { __typename?: 'TemplateEntryCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'TemplateEntry' }
      & { description?: Maybe<(
        { __typename?: 'TemplateEntryDescription' }
        & Pick<TemplateEntryDescription, 'json'>
      )> }
      & TemplateEntryFieldsFragmentFragment
    )>> }
  )> }
);

export type GetFeaturedTemplateEntriesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFeaturedTemplateEntriesQuery = (
  { __typename?: 'Query' }
  & { featuredTemplates?: Maybe<(
    { __typename?: 'FeaturedTemplates' }
    & { templateEntriesCollection?: Maybe<(
      { __typename?: 'FeaturedTemplatesTemplateEntriesCollection' }
      & { items: Array<Maybe<(
        { __typename?: 'TemplateEntry' }
        & { description?: Maybe<(
          { __typename?: 'TemplateEntryDescription' }
          & Pick<TemplateEntryDescription, 'json'>
        )> }
        & TemplateEntryFieldsFragmentFragment
      )>> }
    )> }
  )> }
);

export type GetToursQueryVariables = Exact<{
  where?: Maybe<TourFilter>;
}>;


export type GetToursQuery = (
  { __typename?: 'Query' }
  & { tourCollection?: Maybe<(
    { __typename?: 'TourCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Tour' }
      & Pick<Tour, 'id' | 'title' | 'description'>
      & { stepsCollection?: Maybe<(
        { __typename?: 'TourStepsCollection' }
        & Pick<TourStepsCollection, 'total'>
        & { items: Array<Maybe<(
          { __typename?: 'TourStep' }
          & Pick<TourStep, 'headline' | 'videoUrl' | 'posterUrl' | 'staticImageUrl'>
          & { body?: Maybe<(
            { __typename?: 'TourStepBody' }
            & Pick<TourStepBody, 'json'>
          )>, sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      )> }
    )>> }
  )> }
);

export const InspirationCategoryFieldsFragmentFragmentDoc = gql`
    fragment InspirationCategoryFieldsFragment on InspirationCategory {
  title
  slug
  sys {
    id
  }
  description {
    json
  }
}
    `;
export const InspirationEntryFieldsFragmentFragmentDoc = gql`
    fragment InspirationEntryFieldsFragment on InspirationEntry {
  title
  slug
  description {
    json
  }
  sys {
    id
    firstPublishedAt
  }
  deck {
    title
    docId
    previewCardId
    previewImage {
      url
    }
    author {
      name
      image {
        url
      }
    }
  }
}
    `;
export const TemplateCategoryFieldsFragmentFragmentDoc = gql`
    fragment TemplateCategoryFieldsFragment on TemplateCategory {
  title
  slug
  sys {
    id
  }
  description {
    json
  }
}
    `;
export const TemplateEntryFieldsFragmentFragmentDoc = gql`
    fragment TemplateEntryFieldsFragment on TemplateEntry {
  title
  slug
  sys {
    id
    firstPublishedAt
  }
  deck {
    title
    docId
    previewCardId
    previewImage {
      url
    }
    author {
      name
      image {
        url
      }
    }
  }
}
    `;
export const TemplateCategoryFieldsWithEntriesFragmentFragmentDoc = gql`
    fragment TemplateCategoryFieldsWithEntriesFragment on TemplateCategory {
  ...TemplateCategoryFieldsFragment
  templateEntriesCollection(limit: 7) {
    items {
      ...TemplateEntryFieldsFragment
    }
  }
}
    ${TemplateCategoryFieldsFragmentFragmentDoc}
${TemplateEntryFieldsFragmentFragmentDoc}`;
export const GetInspirationCategoryCollectionDocument = gql`
    query GetInspirationCategoryCollection($where: InspirationCategoryFilter) {
  inspirationCategoryCollection(where: $where, limit: 10, order: priority_DESC) {
    items {
      ...InspirationCategoryFieldsFragment
      inspirationEntriesCollection(limit: 20) {
        items {
          ...InspirationEntryFieldsFragment
        }
      }
    }
  }
}
    ${InspirationCategoryFieldsFragmentFragmentDoc}
${InspirationEntryFieldsFragmentFragmentDoc}`;

/**
 * __useGetInspirationCategoryCollectionQuery__
 *
 * To run a query within a React component, call `useGetInspirationCategoryCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationCategoryCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationCategoryCollectionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInspirationCategoryCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetInspirationCategoryCollectionQuery, GetInspirationCategoryCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspirationCategoryCollectionQuery, GetInspirationCategoryCollectionQueryVariables>(GetInspirationCategoryCollectionDocument, options);
      }
export function useGetInspirationCategoryCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspirationCategoryCollectionQuery, GetInspirationCategoryCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspirationCategoryCollectionQuery, GetInspirationCategoryCollectionQueryVariables>(GetInspirationCategoryCollectionDocument, options);
        }
export type GetInspirationCategoryCollectionQueryHookResult = ReturnType<typeof useGetInspirationCategoryCollectionQuery>;
export type GetInspirationCategoryCollectionLazyQueryHookResult = ReturnType<typeof useGetInspirationCategoryCollectionLazyQuery>;
export type GetInspirationCategoryCollectionQueryResult = Apollo.QueryResult<GetInspirationCategoryCollectionQuery, GetInspirationCategoryCollectionQueryVariables>;
export const GetTemplateCategoryCollectionDocument = gql`
    query GetTemplateCategoryCollection($where: TemplateCategoryFilter) {
  templateCategoryCollection(where: $where, limit: 20, order: priority_DESC) {
    items {
      ...TemplateCategoryFieldsFragment
      templateEntriesCollection(limit: 100) {
        items {
          ...TemplateEntryFieldsFragment
        }
      }
    }
  }
}
    ${TemplateCategoryFieldsFragmentFragmentDoc}
${TemplateEntryFieldsFragmentFragmentDoc}`;

/**
 * __useGetTemplateCategoryCollectionQuery__
 *
 * To run a query within a React component, call `useGetTemplateCategoryCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateCategoryCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateCategoryCollectionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTemplateCategoryCollectionQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateCategoryCollectionQuery, GetTemplateCategoryCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateCategoryCollectionQuery, GetTemplateCategoryCollectionQueryVariables>(GetTemplateCategoryCollectionDocument, options);
      }
export function useGetTemplateCategoryCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateCategoryCollectionQuery, GetTemplateCategoryCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateCategoryCollectionQuery, GetTemplateCategoryCollectionQueryVariables>(GetTemplateCategoryCollectionDocument, options);
        }
export type GetTemplateCategoryCollectionQueryHookResult = ReturnType<typeof useGetTemplateCategoryCollectionQuery>;
export type GetTemplateCategoryCollectionLazyQueryHookResult = ReturnType<typeof useGetTemplateCategoryCollectionLazyQuery>;
export type GetTemplateCategoryCollectionQueryResult = Apollo.QueryResult<GetTemplateCategoryCollectionQuery, GetTemplateCategoryCollectionQueryVariables>;
export const GetTemplateEntryDocument = gql`
    query GetTemplateEntry($id: String!) {
  templateEntry(id: $id) {
    ...TemplateEntryFieldsFragment
    description {
      json
    }
    linkedFrom {
      templateCategoryCollection {
        items {
          ...TemplateCategoryFieldsWithEntriesFragment
        }
      }
    }
  }
}
    ${TemplateEntryFieldsFragmentFragmentDoc}
${TemplateCategoryFieldsWithEntriesFragmentFragmentDoc}`;

/**
 * __useGetTemplateEntryQuery__
 *
 * To run a query within a React component, call `useGetTemplateEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateEntryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateEntryQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateEntryQuery, GetTemplateEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateEntryQuery, GetTemplateEntryQueryVariables>(GetTemplateEntryDocument, options);
      }
export function useGetTemplateEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateEntryQuery, GetTemplateEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateEntryQuery, GetTemplateEntryQueryVariables>(GetTemplateEntryDocument, options);
        }
export type GetTemplateEntryQueryHookResult = ReturnType<typeof useGetTemplateEntryQuery>;
export type GetTemplateEntryLazyQueryHookResult = ReturnType<typeof useGetTemplateEntryLazyQuery>;
export type GetTemplateEntryQueryResult = Apollo.QueryResult<GetTemplateEntryQuery, GetTemplateEntryQueryVariables>;
export const GetAllTemplateEntriesDocument = gql`
    query GetAllTemplateEntries($where: TemplateEntryFilter) {
  templateEntryCollection(where: $where) {
    items {
      ...TemplateEntryFieldsFragment
      description {
        json
      }
    }
  }
}
    ${TemplateEntryFieldsFragmentFragmentDoc}`;

/**
 * __useGetAllTemplateEntriesQuery__
 *
 * To run a query within a React component, call `useGetAllTemplateEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTemplateEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTemplateEntriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllTemplateEntriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTemplateEntriesQuery, GetAllTemplateEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTemplateEntriesQuery, GetAllTemplateEntriesQueryVariables>(GetAllTemplateEntriesDocument, options);
      }
export function useGetAllTemplateEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTemplateEntriesQuery, GetAllTemplateEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTemplateEntriesQuery, GetAllTemplateEntriesQueryVariables>(GetAllTemplateEntriesDocument, options);
        }
export type GetAllTemplateEntriesQueryHookResult = ReturnType<typeof useGetAllTemplateEntriesQuery>;
export type GetAllTemplateEntriesLazyQueryHookResult = ReturnType<typeof useGetAllTemplateEntriesLazyQuery>;
export type GetAllTemplateEntriesQueryResult = Apollo.QueryResult<GetAllTemplateEntriesQuery, GetAllTemplateEntriesQueryVariables>;
export const GetFeaturedTemplateEntriesDocument = gql`
    query GetFeaturedTemplateEntries($id: String!) {
  featuredTemplates(id: $id) {
    templateEntriesCollection {
      items {
        ...TemplateEntryFieldsFragment
        description {
          json
        }
      }
    }
  }
}
    ${TemplateEntryFieldsFragmentFragmentDoc}`;

/**
 * __useGetFeaturedTemplateEntriesQuery__
 *
 * To run a query within a React component, call `useGetFeaturedTemplateEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedTemplateEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedTemplateEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeaturedTemplateEntriesQuery(baseOptions: Apollo.QueryHookOptions<GetFeaturedTemplateEntriesQuery, GetFeaturedTemplateEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedTemplateEntriesQuery, GetFeaturedTemplateEntriesQueryVariables>(GetFeaturedTemplateEntriesDocument, options);
      }
export function useGetFeaturedTemplateEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedTemplateEntriesQuery, GetFeaturedTemplateEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedTemplateEntriesQuery, GetFeaturedTemplateEntriesQueryVariables>(GetFeaturedTemplateEntriesDocument, options);
        }
export type GetFeaturedTemplateEntriesQueryHookResult = ReturnType<typeof useGetFeaturedTemplateEntriesQuery>;
export type GetFeaturedTemplateEntriesLazyQueryHookResult = ReturnType<typeof useGetFeaturedTemplateEntriesLazyQuery>;
export type GetFeaturedTemplateEntriesQueryResult = Apollo.QueryResult<GetFeaturedTemplateEntriesQuery, GetFeaturedTemplateEntriesQueryVariables>;
export const GetToursDocument = gql`
    query GetTours($where: TourFilter) {
  tourCollection(where: $where, limit: 1) {
    items {
      id
      title
      description
      stepsCollection {
        items {
          body {
            json
          }
          headline
          sys {
            id
          }
          videoUrl
          posterUrl
          staticImageUrl
        }
        total
      }
    }
  }
}
    `;

/**
 * __useGetToursQuery__
 *
 * To run a query within a React component, call `useGetToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToursQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetToursQuery(baseOptions?: Apollo.QueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
      }
export function useGetToursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToursQuery, GetToursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetToursQuery, GetToursQueryVariables>(GetToursDocument, options);
        }
export type GetToursQueryHookResult = ReturnType<typeof useGetToursQuery>;
export type GetToursLazyQueryHookResult = ReturnType<typeof useGetToursLazyQuery>;
export type GetToursQueryResult = Apollo.QueryResult<GetToursQuery, GetToursQueryVariables>;